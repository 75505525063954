<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the clinic details</h4>
      <h4 class="mb-2 ml-5 mt-6" style="font-weight: 600">
        Clinic owner or manager details
      </h4>
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.firstName') && clinicData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.firstName') ? true : false"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.firstName')"
                v-model="clinicData.firstName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.lastName') && clinicData.lastName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.lastName')"
                v-model="clinicData.lastName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.email') && clinicData.email != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.email')"
                v-model="clinicData.email"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.password') && clinicData.password != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.password') ? true : false"
                v-validate="'required'"
                name="password"
                data-vv-as="password"
                label="Password"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.password')"
                autocomplete="new-password"
                v-model="clinicData.password"
                icon-no-border
                icon-pack="feather"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>
        </vs-row>

        <h4 class="mb-2 ml-5 mt-6" style="font-weight: 600">
          Clinic business details
        </h4>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.companyName') &&
                  clinicData.companyName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.companyName') ? true : false"
                v-validate="'required'"
                name="companyName"
                data-vv-as="company name"
                label="Company Name"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.companyName')"
                v-model="clinicData.companyName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.clinicName') &&
                  clinicData.clinicName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.clinicName') ? true : false"
                v-validate="'required'"
                name="clinicName"
                :danger-text="errors.first('basic.clinicName')"
                data-vv-as="clinic name"
                label="Clinic Name"
                class="w-full"
                data-vv-scope="basic"
                v-model="clinicData.clinicName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('basic.abn') && clinicData.abn != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.abn') ? true : false"
                v-validate="'required'"
                name="abn"
                :danger-text="errors.first('basic.abn')"
                data-vv-as="ABN"
                label="ABN"
                class="w-full"
                data-vv-scope="basic"
                v-model="clinicData.abn"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <div :class="{ dateClass: !errors.has('abnCertificate') }">
                <label class="vs-input--label custom-label"
                  >ABN Certificate (Please upload your ABN certificate, or a
                  screenshot from https://abr.business.gov.au/)</label
                >
                <div style="margin-top: 3px">
                  <div class="mb-4" v-if="clinicData.abnCertificate">
                    <div
                      v-if="
                        determineFileType(clinicData.abnCertificate) === 'pdf'
                      "
                      style="margin: auto; width: 50%"
                    >
                      <a :href="clinicData.abnCertificate" target="_blank"
                        >Click here to view file</a
                      >
                    </div>
                    <div
                      v-else
                      style="margin: auto"
                      class="register-img-wrapper"
                    >
                      <a :href="clinicData.abnCertificate" target="_blank">
                        <img
                          :src="clinicData.abnCertificate"
                          class="img-fluid"
                        />
                      </a>
                    </div>
                  </div>
                  <div
                    id="abnCertificateUpload"
                    :class="{ 'mt-3': clinicData.abnCertificate }"
                    style="text-align: center; position: relative"
                  >
                    <label
                      id="abnCertificateId"
                      :class="{ 'six-pixel': errors.has('abnCertificate') }"
                      class="
                        image-upload
                        abn-certificate-upload
                        vs-con-loading__container
                      "
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                      "
                    >
                      + Attach Document
                      <input
                        type="file"
                        @change="
                          uploadImage(
                            $event,
                            'abnCertificateUpload',
                            'abn-certificate-upload'
                          )
                        "
                        id="file-input"
                        hidden
                      />
                    </label>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm error-text no-margin-span">
                {{ errors.first("abnCertificate") }}
              </span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                :clinicPhotosField="clinicData.councilPermits"
                from="create"
                loadingClass="councilPermits"
                label="Please upload any required council permits (Optional)"
                popupTitle="Councli Permits"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                :clinicPhotosField="clinicData.poisonsCertificate"
                from="create"
                loadingClass="poisonsCertificate"
                label="Please upload your poisons certificate (For clinics in VIC and WA) (Optional)"
                popupTitle="Poisons Certificate"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <div
                class="
                  vs-component vs-con-input-label vs-input
                  w-full
                  vs-input-primary
                "
                :class="{
                  'input-icon-validate-danger':
                    errors.first('displayAddress') ||
                    (clinicData.address.displayAddress &&
                      !clinicData.address.geometry.coordinates[0]) ||
                    checkDisplayAddress != clinicData.address.displayAddress,
                  'input-icon-validate-success':
                    clinicData.address.geometry.coordinates[0] &&
                    checkDisplayAddress == clinicData.address.displayAddress,
                }"
              >
                <!---->
                <label for class="vs-input--label">Clinic Street Address</label>
                <div class="vs-con-input">
                  <input
                    type="text"
                    placeholder
                    ref="autocomplete"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    data-vv-as="clinic street address"
                    name="displayAddress"
                    v-model="clinicData.address.displayAddress"
                    class="vs-inputx vs-input--input normal"
                    :class="{ hasValue: clinicData.address.displayAddress }"
                    :danger-text="'asd'"
                    style="border: 1px solid rgba(0, 0, 0, 0.2)"
                  />
                  <span class="input-icon-validate vs-input--icon-validate">
                    <i
                      v-if="
                        clinicData.address.geometry.coordinates[0] &&
                        checkDisplayAddress == clinicData.address.displayAddress
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                    <i
                      v-else-if="
                        errors.first('displayAddress') ||
                        (clinicData.address.displayAddress &&
                          !clinicData.address.geometry.coordinates[0]) ||
                        checkDisplayAddress != clinicData.address.displayAddress
                      "
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </div>
              </div>
              <span
                class="text-danger text-sm"
                style="
                  font-size: 0.65rem !important;
                  padding: 2px 4px;
                  padding-bottom: 4px;
                  display: block;
                "
                v-if="
                  errors.first('displayAddress') ||
                  (clinicData.address.displayAddress &&
                    !clinicData.address.geometry.coordinates[0]) ||
                  checkDisplayAddress != clinicData.address.displayAddress
                "
                >Enter valid address.</span
              >
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                data-vv-scope="basic"
                :danger="errors.first('basic.clinicSuburb') ? true : false"
                :danger-text="errors.first('basic.clinicSuburb')"
                :success="
                  !errors.first('basic.clinicSuburb') &&
                  clinicData.address.suburb != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired }"
                data-vv-as="clinic suburb"
                name="clinicSuburb"
                label="Clinic Suburb"
                v-model="clinicData.address.suburb"
                class="w-full"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                data-vv-scope="basic"
                :danger-text="errors.first('basic.clinicPostCode')"
                :danger="errors.first('basic.clinicPostCode') ? true : false"
                :success="
                  !errors.first('basic.clinicPostCode') &&
                  clinicData.address.postCode != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired, integer }"
                data-vv-as="clinic post code"
                name="clinicPostCode"
                label="Clinic Post Code"
                v-model="clinicData.address.postCode"
                class="w-full"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                data-vv-scope="basic"
                :danger-text="errors.first('basic.clinicState')"
                :danger="errors.first('basic.clinicState') ? true : false"
                :success="
                  !errors.first('basic.clinicState') &&
                  clinicData.address.state != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired }"
                data-vv-as="clinic state"
                name="clinicState"
                label="Clinic State"
                v-model="clinicData.address.state"
                class="w-full"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <div style="margin-top: 13px">
                <label
                  style="display: block"
                  class="vs-input--label custom-label mb-1"
                >
                  Is this a residential address?
                </label>
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios1"
                  style="margin-right: 20px"
                  v-model="clinicData.isResidentialAddress"
                  >Yes</vs-radio
                >
                <vs-radio
                  vs-value="No"
                  vs-name="radios1"
                  v-model="clinicData.isResidentialAddress"
                  >No</vs-radio
                >
                <span
                  v-if="validationKeys.showIsResidentialAddressError"
                  style="display: block"
                  class="text-danger text-sm error-text no-margin-span"
                  >Please select one option.</span
                >
              </div>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                data-vv-scope="basic"
                :danger-text="errors.first('basic.clinicAddress1')"
                :danger="errors.first('basic.clinicAddress1') ? true : false"
                :success="
                  !errors.first('basic.clinicAddress1') &&
                  clinicData.clinicAddress1 != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="Clinic Address 1"
                name="clinicAddress1"
                label="Clinic Address 1 (Optional)"
                v-model="clinicData.clinicAddress1"
                class="w-full"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.postalAddress') &&
                  clinicData.postalAddress !== ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.postalAddress') ? true : false"
                name="postalAddress"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.postalAddress')"
                data-vv-as="postal address"
                label="Clinic Postal Address (Optional)"
                class="w-full"
                v-model="clinicData.postalAddress"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.bestContactName') &&
                  clinicData.bestContactName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-scope="basic"
                :danger="errors.first('basic.bestContactName') ? true : false"
                v-validate="{ required: isRequired }"
                :danger-text="errors.first('basic.bestContactName')"
                name="bestContactName"
                data-vv-as="best contact name"
                label="Best Contact Name"
                class="w-full"
                v-model="clinicData.bestContactName"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.bestContactNumber') &&
                  clinicData.bestContactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.bestContactNumber') ? true : false"
                v-validate="'required'"
                data-vv-scope="basic"
                name="bestContactNumber"
                :danger-text="errors.first('basic.bestContactNumber')"
                data-vv-as="best contact number"
                label="Best Contact Number"
                class="w-full"
                v-model="clinicData.bestContactNumber"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.bestContactEmail') &&
                  clinicData.bestContactEmail != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.bestContactEmail') ? true : false"
                v-validate="{ required: isRequired, email }"
                data-vv-scope="basic"
                name="bestContactEmail"
                :danger-text="errors.first('basic.bestContactEmail')"
                data-vv-as="best contact email"
                label="Best Contact Email"
                class="w-full"
                v-model="clinicData.bestContactEmail"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('instagram') ? true : false"
                :success="
                  !errors.first('instagram') && clinicData.instagram != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="instagram"
                name="instagram"
                label="Instagram (Optional)"
                v-model="clinicData.instagram"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("instagram")
              }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('facebook') ? true : false"
                :success="
                  !errors.first('facebook') && clinicData.facebook != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="facebook"
                name="facebook"
                label="Facebook (Optional)"
                v-model="clinicData.facebook"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("facebook")
              }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('website') ? true : false"
                :success="!errors.first('website') && clinicData.website != ''"
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="website"
                name="website"
                label="Website (Optional)"
                v-model="clinicData.website"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("website")
              }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('contactNumber') ? true : false"
                :success="
                  !errors.first('contactNumber') &&
                  clinicData.contactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                name="phoneNumber"
                label="Phone Number (Optional)"
                v-model="clinicData.contactNumber"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("contactNumber")
              }}</span>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('basic.invoiceEmail') &&
                  clinicData.invoiceEmail !== ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.invoiceEmail') ? true : false"
                v-validate="{ required: isRequired, email }"
                name="invoiceEmail"
                :danger-text="errors.first('basic.invoiceEmail')"
                data-vv-scope="basic"
                data-vv-as="invoice email"
                label="Invoice Email"
                class="w-full"
                v-model="clinicData.invoiceEmail"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label>Clinic Payment Terms Access</label>
              <vs-switch v-model="clinicData.isAccessPaymentTerms" />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <div style="margin-top: 13px">
                <label
                  style="display: block"
                  class="vs-input--label custom-label mb-1"
                >
                  Do you have adrenaline and hyalase in the clinic?
                </label>
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios99"
                  style="margin-right: 20px"
                  v-model="clinicData.haveAdrenalineAndHyalaseInClinic"
                  >Yes</vs-radio
                >
                <vs-radio
                  vs-value="No"
                  vs-name="radios99"
                  v-model="clinicData.haveAdrenalineAndHyalaseInClinic"
                  >No</vs-radio
                >
                <span
                  v-if="
                    validationKeys.showHaveAdrenalineAndHyalaseInClinicError
                  "
                  style="display: block"
                  class="text-danger text-sm error-text no-margin-span"
                  >Please select one option.</span
                >
              </div>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <div style="margin-top: 13px">
                <label
                  style="display: block"
                  class="vs-input--label custom-label mb-1"
                >
                  Do you intend to secure S4 medication for your clinic through
                  Fresh Clinics
                </label>
                <vs-radio
                  vs-value="Yes"
                  vs-name="radios999"
                  style="margin-right: 20px"
                  v-model="clinicData.intendToSecureS4Medication"
                  >Yes</vs-radio
                >
                <vs-radio
                  vs-value="No"
                  vs-name="radios999"
                  style="margin-right: 20px"
                  v-model="clinicData.intendToSecureS4Medication"
                  >No</vs-radio
                >
                <vs-radio
                  vs-value="Unsure"
                  vs-name="radios999"
                  v-model="clinicData.intendToSecureS4Medication"
                  >Unsure</vs-radio
                >
                <span
                  v-if="validationKeys.showIntendToSecureS4MedicationError"
                  style="display: block"
                  class="text-danger text-sm error-text no-margin-span"
                  >Please select one option.</span
                >
              </div>
            </div>
          </vs-col>

          <clinic-opening-hours
            ref="stepTwoRef"
            :weekDaysData="clinicData.clinicOpeningHours"
            from="create"
          />

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="stepThreeRef"
                :clinicPhotosField="clinicData.clinicFrontPhoto"
                from="create"
                loadingClass="clinicFrontPhoto"
                label="Upload Clinic Front Photos (Please upload a photo of the front of your clinic (from the street))"
                popupTitle="Clinic Front Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step4Ref"
                :clinicPhotosField="clinicData.clinicFloorPhoto"
                from="create"
                loadingClass="clinicFloorPhoto"
                label="Upload Clinic Floor Photos (Please upload photos of the floor of the treatment areas of your clinic)"
                popupTitle="Clinic Floor Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step5Ref"
                :clinicPhotosField="clinicData.clinicTreatmentBedPhoto"
                from="create"
                loadingClass="clinicTreatmentBedPhoto"
                label="Treatment bed (Please upload photos of the treatment beds in your clinic)"
                popupTitle="Clinic Treatment Bed Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step6Ref"
                :clinicPhotosField="clinicData.clinicDrawingUpAreaPhoto"
                from="create"
                loadingClass="clinicDrawingUpAreaPhoto"
                label="Drawing up area (Please upload photos of the drawing up areas in your treatment rooms. Please show gloves and hand-washing facilities, if available)"
                popupTitle="Clinic Drawing Up Area Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step7Ref"
                :clinicPhotosField="clinicData.clinicFridgePhoto"
                from="create"
                loadingClass="clinicFridgePhoto"
                label="Fridge (Please upload photos of the fridge for medication storage, including your temperature monitoring device. Show your lock if available)"
                popupTitle="Clinic Fridge Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step8Ref"
                :clinicPhotosField="clinicData.clinicDrugStoragePhoto"
                from="create"
                loadingClass="clinicDrugStoragePhoto"
                label="Drug Storage (Please upload photos of the cupboard where other medications will be stored. Show the ability to lock the cupboard, if possible.)"
                popupTitle="Clinic Drug Storage Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step9Ref"
                :clinicPhotosField="clinicData.clinicBinsPhoto"
                from="create"
                loadingClass="clinicBinsPhoto"
                label="Bins (Please show photos of your sharps bins and general storage bins.)"
                popupTitle="Clinic Bins Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step10Ref"
                :clinicPhotosField="clinicData.clinicRoomLockPhoto"
                from="create"
                loadingClass="clinicRoomLockPhoto"
                label="Room Lock (Please show photos of the locks on your treatment room, if available.) (Optional)"
                popupTitle="Clinic Room Lock Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <ClinicPhotos
                ref="step11Ref"
                :clinicPhotosField="clinicData.clinicEpipenAndHyalasePhoto"
                from="create"
                loadingClass="clinicEpipenAndHyalasePhoto"
                label="Emergency Medication (Please upload photos of your epipen (or adrenaline) and hyalase. Please show expiry dates in the images. If you do not have these medications you may purchase them after signing up.) (Optional)"
                popupTitle="Clinic Emergency Medication Photos"
                @removeImage="removeImage"
              />
            </div>
          </vs-col>

          <vs-col vs-w="6"></vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button
                size="normal"
                @click="saveData"
                icon-pack="feather"
                class="mr-2"
                >Create Clinic</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import clinicViewEditDetail from "../../components/clinics/viewEditDetail.vue";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import axios from "@/axios";

import ClinicOpeningHours from "./ClinicOpeningHours.vue";
import ClinicPhotos from "./ClinicPhotos";

const dict = {
  custom: {
    bestContactNumber: {
      required: "Please enter your best contact number",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "clinicViewEditDetail",
  inject: ["$validator"],
  components: {
    ClinicOpeningHours,
    ClinicPhotos,
  },
  props: {
    backRoute: {
      type: String,
      default: "SuperAdminClinic",
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    isRequired: true,
    validationKeys: {
      showIsResidentialAddressError: false,
      showIntendToSecureS4MedicationError: false,
      showHaveAdrenalineAndHyalaseInClinicError: false,
    },
    checkDisplayAddress: "",
    addressData: [],
    clinicData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      companyName: "",
      clinicName: "",
      abn: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      },
      postalAddress: "",
      bestContactName: "",
      bestContactNumber: "",
      bestContactEmail: "",
      invoiceEmail: "",
      status: "Active",
      contactNumber: "",
      instagram: "",
      facebook: "",
      website: "",
      clinicAddress1: "",
      isResidentialAddress: "",
      haveAdrenalineAndHyalaseInClinic: "",
      intendToSecureS4Medication: "",
      abnCertificate: "",
      councilPermits: [],
      poisonsCertificate: [],
      clinicOpeningHours: {
        mondays: "",
        tuesdays: "",
        wednesdays: "",
        thursdays: "",
        fridays: "",
        saturdays: "",
        sundays: "",
      },
      clinicFrontPhoto: [],
      clinicFloorPhoto: [],
      clinicTreatmentBedPhoto: [],
      clinicDrawingUpAreaPhoto: [],
      clinicFridgePhoto: [],
      clinicDrugStoragePhoto: [],
      clinicBinsPhoto: [],
      clinicRoomLockPhoto: [],
      clinicEpipenAndHyalasePhoto: [],
      isAccessPaymentTerms: false,
    },
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
  }),
  methods: {
    ...mapActions("clinic", ["createClinic", "removeClinicImages"]),
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "abnCertificateUpload") {
            document.getElementById("abnCertificateId").style.borderColor =
              "#cccccc";
            self.clinicData.abnCertificate = res.data.Location;
            this.errors.remove("abnCertificate");
          }

          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    removeImage(imageUrl, selector, loadingClass) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `#${selector}`,
        scale: 0.45,
        text: "removing",
      });

      let self = this;

      self
        .removeClinicImages(imageUrl)
        .then((response) => {
          self.clinicData[loadingClass] = self.clinicData[loadingClass].filter(
            (value) => value.photoUrl != imageUrl
          );
          self.$vs.loading.close(`#${selector} > .con-vs-loading`);
        })
        .catch((error) => {
          self.$vs.notify({
            title: "Removing Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          self.$vs.loading.close(`#${selector} > .con-vs-loading`);
        });
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let self = this;
      let user = self.$store.state.AppActiveUser.userRole;
      let isValid = await this.$validator.validateAll("basic");

      if (
        this.$store.state.AppActiveUser.userType !== "superAdmin" &&
        this.$store.state.AppActiveUser.userType !== "admin" &&
        this.$store.state.AppActiveUser.userType !== "franchise" &&
        this.$store.state.AppActiveUser.userType !== "subFranchise"
      ) {
        isValid = await this.$validator.validate();
        isValid = await this.$refs.stepTwoRef.$validator.validate();
        isValid = await this.$refs.stepThreeRef.$validator.validate();
        isValid = await this.$refs.step4Ref.$validator.validate();
        isValid = await this.$refs.step5Ref.$validator.validate();
        isValid = await this.$refs.step6Ref.$validator.validate();
        isValid = await this.$refs.step7Ref.$validator.validate();
        isValid = await this.$refs.step8Ref.$validator.validate();
        isValid = await this.$refs.step9Ref.$validator.validate();
        isValid = await this.$refs.step10Ref.$validator.validate();
        isValid = await this.$refs.step11Ref.$validator.validate();

        if (self.clinicData.isResidentialAddress === "") {
          isValid = false;
          this.validationKeys.showIsResidentialAddressError = true;
        } else {
          this.validationKeys.showIsResidentialAddressError = false;
        }

        if (self.clinicData.intendToSecureS4Medication === "") {
          isValid = false;
          this.validationKeys.showIntendToSecureS4MedicationError = true;
        } else {
          this.validationKeys.showIntendToSecureS4MedicationError = false;
        }

        if (self.clinicData.haveAdrenalineAndHyalaseInClinic === "") {
          isValid = false;
          this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = true;
        } else {
          this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
        }

        if (self.clinicData.abnCertificate == "") {
          this.errors.add({
            field: "abnCertificate",
            msg: "ABN Certificate is required",
          });
          document.getElementById("abnCertificateId").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("abnCertificateId").style.borderColor =
            "#cccccc";
          this.errors.remove("abnCertificate");
        }

        if (self.clinicData.clinicFrontPhoto.length === 0) {
          this.$refs.stepThreeRef.errors.add({
            field: "clinicFrontPhoto",
            msg: "Clinic Front Photo is required",
          });
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepThreeRef.errors.remove("clinicFrontPhoto");
        }

        if (self.clinicData.clinicFloorPhoto.length === 0) {
          this.$refs.step4Ref.errors.add({
            field: "clinicFloorPhoto",
            msg: "Clinic Floor Photo is required",
          });
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step4Ref.errors.remove("clinicFloorPhoto");
        }

        if (self.clinicData.clinicTreatmentBedPhoto.length === 0) {
          this.$refs.step5Ref.errors.add({
            field: "clinicTreatmentBedPhoto",
            msg: "Clinic Treatment Bed Photo is required",
          });
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step5Ref.errors.remove("clinicTreatmentBedPhoto");
        }

        if (self.clinicData.clinicDrawingUpAreaPhoto.length === 0) {
          this.$refs.step6Ref.errors.add({
            field: "clinicDrawingUpAreaPhoto",
            msg: "Clinic Drawing Up Area Photo is required",
          });
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#ea5455";
          isValid = false;
        } else {
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#cccccc";
          this.$refs.step6Ref.errors.remove("clinicDrawingUpAreaPhoto");
        }

        if (self.clinicData.clinicFridgePhoto.length === 0) {
          this.$refs.step7Ref.errors.add({
            field: "clinicFridgePhoto",
            msg: "Clinic Fridge Photo is required",
          });
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#cccccc";
          this.$refs.step7Ref.errors.remove("clinicFridgePhoto");
        }

        if (self.clinicData.clinicDrugStoragePhoto.length === 0) {
          this.$refs.step8Ref.errors.add({
            field: "clinicDrugStoragePhoto",
            msg: "Clinic Drug Storage Photo is required",
          });
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#cccccc";
          this.$refs.step8Ref.errors.remove("clinicDrugStoragePhoto");
        }

        if (self.clinicData.clinicBinsPhoto.length === 0) {
          this.$refs.step9Ref.errors.add({
            field: "clinicBinsPhoto",
            msg: "Clinic Bins Photo is required",
          });
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#cccccc";
          this.$refs.step9Ref.errors.remove("clinicBinsPhoto");
        }
      }

      if (
        !self.clinicData.address.geometry.coordinates[0] ||
        self.checkDisplayAddress != self.clinicData.address.displayAddress
      ) {
        isValid = false;
        this.errors.add({
          field: "displayAddress",
          msg: "Enter valid address.",
        });
      } else {
        this.errors.remove("displayAddress");
      }

      if (isValid) {
        // if (this.addressData.length > 0) {
        //   this.addressData.forEach(function(address) {
        //     if (address.types[0] === "administrative_area_level_2") {
        //       self.clinicData.address.suburb = address.long_name;
        //     }
        //     if (address.types[0] === "administrative_area_level_1") {
        //       self.clinicData.address.state = address.long_name;
        //     }
        //     if (address.types[0] === "postal_code") {
        //       self.clinicData.address.postCode = address.long_name;
        //     }
        //   });
        // }

        let formData = this.clinicData;
        formData.emailVerifiedAt = new Date().toISOString();
        if (
          this.$store.state.AppActiveUser.userType === "superAdmin" ||
          this.$store.state.AppActiveUser.userType === "admin"
        ) {
          formData.hasApprovedByAdmin = true;
        }
        if (this.franchiseId && this.franchiseId !== "") {
          formData.franchiseId = this.franchiseId;
        }

        this.$vs.loading();

        await this.createClinic(formData)
          .then((res) => {
            self.$vs.notify({
              title: "Clinic Created",
              text: "Clinic created Successfully.",
              color: "success",
            });
            if (user === "superAdmin") {
              self.$router.push("/super-admin/clinics");
            } else if (user === "subFranchise") {
              self.$router.push("/franchise/clinics");
            } else {
              self.$router.push("/" + user + "/clinics");
            }
            self.$vs.loading.close();
          })
          .catch((err) => {
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
              self.$vs.notify({
                title: "Failed",
                text: "Failed to created clinic. Try again!",
                color: "danger",
              });
            }
            if (err.status === 417) {
              if (err.data.title === "Invalid email") {
                self.errors.add({
                  field: "email",
                  msg: err.data.message,
                  scope: "basic",
                });
              } else if (err.data.title === "Invalid ABN") {
                self.errors.add({
                  field: "abn",
                  msg: err.data.message,
                  scope: "basic",
                });
              }
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }
            self.$vs.loading.close();
          });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
  },

  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.addressData = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.clinicData.address.displayAddress = place.formatted_address;
      this.clinicData.address.geometry.coordinates[0] = lon;
      this.clinicData.address.geometry.coordinates[1] = lat;

      let self = this;

      self.clinicData.address.suburb = "";
      self.clinicData.address.state = "";
      self.clinicData.address.postCode = "";
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach(function (gAdd) {
          if (gAdd.types[0] === "administrative_area_level_2") {
            self.clinicData.address.suburb = gAdd.long_name;
            self.errors.remove("clinicSuburb");
          }
          if (gAdd.types[0] === "administrative_area_level_1") {
            self.clinicData.address.state = gAdd.long_name;
            self.errors.remove("clinicState");
          }
          if (gAdd.types[0] === "postal_code") {
            self.clinicData.address.postCode = gAdd.long_name;
            self.errors.remove("clinicPostCode");
          }
        });
      }
    });
  },
  created() {
    this.isRequired =
      this.$store.state.AppActiveUser.userType === "superAdmin" ||
      this.$store.state.AppActiveUser.userType === "admin" ||
      this.$store.state.AppActiveUser.userType === "franchise" ||
      this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;
  },
  watch: {
    "clinicData.isResidentialAddress": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIsResidentialAddressError = false;
      }
    },
    "clinicData.intendToSecureS4Medication": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIntendToSecureS4MedicationError = false;
      }
    },
    "clinicData.haveAdrenalineAndHyalaseInClinic": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
      }
    },
  },
};
</script>

<style scoped>
.error-text {
  font-size: 0.65rem !important;
  padding: 2px 4px 4px;
  display: block;
  margin-top: -6px;
}

.text-sm {
  font-size: 0.65rem !important;
}

.no-margin-span {
  margin-top: 0 !important;
}
</style>
